import { Amount } from '@neonevm/token-transfer-core';
import { Big } from 'big.js';
import { MICRO_LAMPORTS, UNITS_LIMIT } from './consts';

export function neonTransferFeeBig(operator_fee: string, solUsd: number, neonUsd: number, lamport: Amount): Big {
  const operatorFee = new Big(1 + parseInt(operator_fee, 16) / 10000);
  const solPriceUSD = new Big(solUsd);
  const neonPriceUSD = new Big(neonUsd);
  const solPerNeon = solPriceUSD.div(neonPriceUSD);
  const solanaGasPrice = operatorFee.times(solPerNeon);
  const fee = solanaGasPrice.times(lamport.toString()).round();
  return fee.gt(0) ? fee : new Big(10000);
}

export function priorityFeeLamports(microLamports: number, unitsLimit = UNITS_LIMIT): Big {
  return new Big(microLamports).times(unitsLimit).times(MICRO_LAMPORTS);
}

export function fractionLength(amount: number): number {
  return amount < 2e4 ? 6 : 5;
}

export function toAmountView(amount: Amount, decimals: number): string {
  const a = new Big(amount.toString()).div(new Big(10).pow(decimals));
  const fraction = fractionLength(a.toNumber());
  return a.toFixed(fraction);
}

export function isNumber(str: string): boolean {
  return /^[0-9]+[.,]?[0-9]*$/.test(str);
}
