import { Injectable } from '@angular/core';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { AppKit } from '@web3modal/base';
import { erc20Abi, neonWrapper2Abi, SPLToken } from '@neonevm/token-transfer-core';
import { Chain } from '@web3modal/scaffold-utils/dist/types/src/ethers/EthersTypesUtil';
import type { EthersStoreUtilState } from '@web3modal/scaffold-utils/dist/types/exports/ethers';

import { BrowserProvider, TransactionReceipt, TransactionRequest } from 'ethers';
import { BehaviorSubject, delay, filter, from, map, Observable, ReplaySubject, Subject, switchMap, tap } from 'rxjs';
import { Big } from 'big.js';
import { environment } from '../../environments/environment';
import {
  DataStorage,
  EXCLUDED_WALLETS,
  metadata,
  NEON_WALLETS,
  neonBalance,
  neonEthersDevnet,
  neonEthersMainnet,
  tokenBalance
} from '../../utils';
import { NotificationService } from '../../notifications';
import { Address, NeonTokenBalance, NeonWallet } from '../../models';

@Injectable({ providedIn: 'root' })
export class WalletConnectService extends DataStorage<any> {
  projectId: string = environment.walletConnect.projectId;
  address$: BehaviorSubject<Address> = new BehaviorSubject<Address>(undefined!);
  balance$: BehaviorSubject<Big> = new BehaviorSubject<Big>(new Big(0));
  wallet$: BehaviorSubject<NeonWallet> = new BehaviorSubject<NeonWallet>(undefined!);
  chainId$: BehaviorSubject<number> = new BehaviorSubject<number>(environment.chainId!);
  provider: BrowserProvider;
  provider$: ReplaySubject<BrowserProvider> = new ReplaySubject<BrowserProvider>(0);
  refresh$: Subject<boolean> = new Subject<boolean>();
  connected$ = new BehaviorSubject<boolean>(false);
  isSameNetwork$ = new ReplaySubject<boolean>(0);
  chains: Chain[] = [neonEthersDevnet, neonEthersMainnet];
  modal: AppKit<EthersStoreUtilState, number>;
  private _snapshot: EthersStoreUtilState;

  get address(): `0x${string}` {
    return this.address$.value;
  }

  get addressView$(): Observable<string> {
    return this.address$.pipe(map(d => {
      if (d?.length) {
        return `${d.slice(0, 7)}..${d.slice(-5)}`;
      }
      return '';
    }));
  }

  get chainId(): number {
    return this.chainId$.value;
  }

  get publicClient(): any {
    return this.provider.getSigner();
  }

  setProvider = (p: any) => {
    if (p) {
      const ethersProvider = new BrowserProvider(p);
      this.provider = ethersProvider;
      this.provider$.next(ethersProvider);
    }
  };

  neonBalance(): Observable<NeonTokenBalance> {
    return from(neonBalance(this.provider));
  }

  wNeonBalance(token: SPLToken): Observable<NeonTokenBalance> {
    return from(tokenBalance(this.provider, token, neonWrapper2Abi));
  }

  tokenBalance(token: SPLToken): Observable<NeonTokenBalance> {
    return from(tokenBalance(this.provider, token, erc20Abi));
  }

  getTransactionReceipt = async (transaction: TransactionRequest): Promise<TransactionReceipt | null> => {
    const signer = await this.provider.getSigner();
    const result = await signer.sendTransaction(transaction);
    return this.provider.getTransactionReceipt(result.hash);
  };

  isSameNetworkEmit(): void {
    this.subs.push(this.chainId$.pipe(tap(chainId => {
      this.isSameNetwork$.next(chainId ? Number(chainId) === environment.chainId : true);
    })).subscribe());
  }

  providerNotifications(data: EthersStoreUtilState): void {
    const { provider, isConnected, chainId } = data;
    if (provider && isConnected) {
      if (this._snapshot && this._snapshot?.chainId && this._snapshot?.chainId !== chainId) {
        this.n.success({ title: 'Network changed' });
      } else {
        this.n.success({ title: 'Neon wallet connected' });
      }
    }
    if (!isConnected && this._snapshot && this._snapshot?.isConnected !== isConnected) {
      this.n.success({ title: 'Neon wallet disconnected' });
    }
    this._snapshot = { ...data };
  }

  open(): void {
    this.modal.open();
  }

  signMessage(message: string): Observable<string> {
    return new Observable<string>(subscriber => {
      const signer = this.provider.getSigner()
        .then(signer => signer.signMessage(message))
        .then(hex => subscriber.next(hex))
        .catch(e => subscriber.next(e))
        .finally(() => subscriber.complete());
    });
  }

  walletBalance(address: Address): Observable<Big> {
    return from(this.provider.getBalance(address)).pipe(map((b: unknown) => {
      const balance = new Big(typeof b === 'bigint' ? b.toString() : 0);
      this.balance$.next(balance);
      return balance;
    }));
  }

  walletBalanceClean(): Observable<Big> {
    this.balance$.next(new Big(0));
    return this.balance$.pipe(delay(100));
  }

  async disconnect(): Promise<any> {
    this.modal.open();
  }

  init(): void {
    const projectId = this.projectId;
    const ethersConfig = defaultConfig({
      metadata,
      enableEIP6963: true,
      enableInjected: true,
      auth: { email: false, socials: [] }
    });

    this.modal = createWeb3Modal({
      ethersConfig,
      chains: [neonEthersDevnet, neonEthersMainnet],
      projectId,
      enableAnalytics: false, // Optional - defaults to your Cloud configuration
      featuredWalletIds: NEON_WALLETS.filter(d => d.name !== 'Taho').map(w => w.id),
      includeWalletIds: NEON_WALLETS.map(d => d.id),
      excludeWalletIds: EXCLUDED_WALLETS
    });

    this.modal.subscribeProvider((data: EthersStoreUtilState): void => {
      const { provider, providerType, address, error, chainId, isConnected } = data;
      this.setProvider(provider);
      this.connected$.next(isConnected);
      this.chainId$.next(chainId!);
      this.address$.next(address!);
      this.providerNotifications(data);
    });

    this.subs.push(this.address$.pipe(switchMap(address => address?.length ?
      this.walletBalance(<Address>address) : this.walletBalanceClean())).subscribe());
    this.subs.push(this.refresh$.pipe(filter(() => !!this.address), switchMap(() => {
      return this.walletBalance(<Address>this.address);
    })).subscribe());
    this.isSameNetworkEmit();
  }

  refresh(): void {
    this.refresh$.next(true);
  }

  constructor(private n: NotificationService) {
    super();
  }
}
