import {
  RpcResponseAndContext,
  SignatureResult,
  SimulatedTransactionResponse,
  Transaction,
  TransactionSignature
} from '@solana/web3.js';
import { GasToken, SPLToken } from '@neonevm/token-transfer-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Big } from 'big.js';
import { map } from 'rxjs/operators';
import { TransactionRequest, TransactionResponse, TransactionResponseParams } from 'ethers';

export type Amount = number | bigint | string;

export type Address = `0x${string}`;

export const enum TransferDirection {
  neon = 'neon',
  solana = 'solana'
}

export interface Balance<T> {
  status: 'loading' | 'loaded';
  balance?: T;
}

export interface TokenBalance {
  token: SPLToken;
  solana: BehaviorSubject<Balance<TokenAmount>>;
  neon: BehaviorSubject<Balance<TokenAmount>>;
}

export interface NeonTokenBalance {
  decimals: number;
  formatted: string;
  symbol: string;
  value: bigint;
}

export interface TokenAmount {
  amount: Amount;
  decimals: number;
}

export interface TransferTokenFormData {
  direction: TransferDirection;
  token: TransferToken;
  amount: Amount;
  priorityFee: number;
  rewardAmount?: Amount;
  rewardFrom?: 'neon' | 'solana';
}

export const enum TokenTransferStatus {
  form = 0,
  transfer = 1
}

export interface TransferTokenFee {
  solanaFee: Big;
  neonFee: Big;
}

export interface MultiTokenFee {
  network: string;
  token: string;
  fee: Big;
  chainId?: string;
  mint?: string;
}

export const enum PendingStatus {
  default = 0,
  started = 1,
  signature = 2,
  signed = 3,
  confirmed = 4,
  rejected = 5,
  wrap = 6,
  unwrap = 7,
}

export interface SolanaTransaction {
  transaction: Transaction;
  signature?: TransactionSignature;
}

export interface NeonTransaction {
  transaction: TransactionResponseParams;
  signature?: TransactionResponse;
}

export interface SolanaTokenTransaction {
  timestamp: number;
  token?: SPLToken;
  amount?: Amount;
  transaction?: Transaction;
}

export interface NeonTokenTransaction {
  timestamp: number;
  token?: SPLToken;
  amount?: Amount;
  transaction?: TransactionRequest;
}

export interface TransferTransaction {
  solana?: SolanaTransaction;
  neon?: NeonTransaction;
}

export interface TransferTransactionConfirmation<T = any> {
  solana?: SignatureResult;
  neon?: T; //We need to maintain backward compatibility
}

export interface TransferTokenLog {
  error?: any;
  data?: TransferTokenFormData;
  transaction?: TransferTransaction;
  transactionConfirmation?: TransferTransactionConfirmation;
  method?: string;
}

export interface PriorityFee {
  id: number;
  label: string;
  value: number;
  amount: number;
  reward: 'solana' | 'neon';
  type: 'standard' | 'medium' | 'fast' | 'custom';
}

export interface PriorityFeeData {
  fees: PriorityFee[];
  customFee: PriorityFee;
  reward: 'neon' | 'solana';
  selected$: BehaviorSubject<PriorityFee>;
  isDisabled: boolean;

  isSelected(item: PriorityFee): boolean;

  calcValue(item: PriorityFee): string;

  trackByFn(i: number, item: PriorityFee): number;

  select(item: PriorityFee): void;
}

export interface PriorityFeesResponse {
  priorityFeeLevels: {
    high: number;
    low: number;
    medium: number;
    min: number;
    unsafeMax: number;
    veryHigh: number;
  };
}

export interface PriorityFeeRecUnits {
  fees: PriorityFeeRec;
  units: number;
}

export interface PriorityFeeRec {
  standard: number;
  medium: number;
  fast: number;
  custom?: number;
}

export interface TransactionSimulateResponse {
  transaction: Transaction;
  simulate: RpcResponseAndContext<SimulatedTransactionResponse>;
}

export interface TokensDialogData {
  direction: TransferDirection;
}

export class MultiTokenGasFee implements MultiTokenFee {
  network: string;
  token: string;
  fee: Big;
  chainId?: string;
  mint?: string;

  constructor(data: GasToken, prefix = `neon`) {
    this.network = `${prefix}_${data.tokenName}`;
    this.token = data.tokenName;
    this.chainId = data.tokenChainId;
    this.mint = data.tokenMint;
    this.fee = new Big(0);
  }

  setFee(fee: Big): void {
    this.fee = new Big(fee);
  }
}

export class TransferToken implements TokenBalance {
  token: SPLToken;
  solana: BehaviorSubject<Balance<TokenAmount>>;
  neon: BehaviorSubject<Balance<TokenAmount>>;

  constructor(token: SPLToken) {
    this.token = token;
    this.solana = new BehaviorSubject<Balance<TokenAmount>>({ status: 'loading', balance: undefined });
    this.neon = new BehaviorSubject<Balance<TokenAmount>>({ status: 'loading', balance: undefined });
  }

  get symbol(): string {
    return this.token.symbol ?? ``;
  }

  solanaUpdate(balance: TokenAmount): void {
    this.solana.next({ balance, status: 'loaded' });
  }

  neonUpdate(balance: TokenAmount): void {
    this.neon.next({ balance, status: 'loaded' });
  }

  solanaReset(): void {
    this.solana.next({ status: 'loading', balance: undefined });
  }

  neonReset(): void {
    this.neon.next({ status: 'loading', balance: undefined });
  }

  balance(direction: TransferDirection): Balance<TokenAmount> {
    return (direction === TransferDirection.neon ? this.neon : this.solana).value;
  }

  balance$(direction: TransferDirection): Observable<Balance<TokenAmount>> {
    return direction === TransferDirection.neon ? this.neon : this.solana;
  }

  tokenAmount(direction: TransferDirection): TokenAmount | null {
    const { balance } = this.balance(direction);
    return balance ? balance : null;
  }

  amount(direction: TransferDirection): Big {
    const { balance } = this.balance(direction);
    if (balance) {
      const { amount } = balance;
      return new Big(amount.toString());
    }
    return new Big(0);
  }

  amountView(direction: TransferDirection): Big {
    const { balance } = this.balance(direction);
    if (balance) {
      const { amount, decimals } = balance;
      return new Big(amount.toString()).div(new Big(10).pow(decimals));
    }
    return new Big(0);
  }

  amountView$(direction: TransferDirection): Observable<Big> {
    return this.balance$(direction).pipe(map(({ balance }) => {
      if (balance) {
        const { amount, decimals } = balance;
        return new Big(amount.toString()).div(new Big(10).pow(decimals));
      }
      return new Big(0);
    }));
  }

  toAmount(amount: Amount, direction: TransferDirection): Big {
    const { balance } = this.balance(direction);
    if (balance) {
      const { decimals } = balance;
      return new Big(amount.toString()).times(new Big(10).pow(decimals));
    }
    return new Big(0);
  }
}
