export const environment = {
  environment: 'production',
  production: true,
  network: 'mainnet',
  chainId: 245022934,
  chainName: 'Neon EVM (Mainnet)',
  neon: {
    token_mint: `NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44`,
    token_contract: `0xaC6FEaF379B01e3B0888597c53bb37e2B0b513ab`,
    token_contract_sol: `0xaC6FEaF379B01e3B0888597c53bb37e2B0b513ab`,
    decimals: { token: 9, token_mint: 18 }
  },
  helius: {
    url: `https://atlas.neonevm.org`,
    secure: `https://karine-egne3h-fast-mainnet.helius-rpc.com`,
    api_key: `404b816c-9063-430e-91df-234ebd375d59`
  },
  urls: {
    solana: `https://endp.neonpass.live/yLnJ5aDbssvdo5vBshOn`,
    neon: `https://neon-proxy-mainnet.solana.p2p.org`,
    everstake: `https://neon-mainnet.everstake.one`,
    neonpassService: `https://service.neonpass.live`
  },
  neonScan: 'https://neonscan.org',
  walletConnect: {
    projectId: 'c297fee968f5e8c588cef7c43dd7e358'
  },
  siteUrl: 'https://neonpass.live',
  measurementId: 'G-5W7C99Q6VT'
};
